import { useEffect } from 'react';

export const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const scrollingTo = (id) => {
    const elementPosition = document.querySelector(id).offsetTop;

    sleep(100).then(() => {
        window.scroll({
            top: elementPosition - '80',
            behavior: 'smooth',
        });
    });
};

export const scrollOnHashChange = (path, target) => {
    const includedInPath = (elementId) => path.includes(elementId);

    if (Array.isArray(target)) {
        const elementId = target.find(includedInPath);
        if (elementId) {
            scrollingTo(elementId);
        }
    }

    if (path.includes(target)) {
        scrollingTo('#request-a-demo');
    }
};

export const useScrollToElementOnHashChange = (target) => {
    useEffect(() => {
        const hash = window.location.href;
        const scroll = () => scrollOnHashChange(hash, target);

        scrollOnHashChange(hash, target);
        window.addEventListener('hashchange', scroll, false);

        return () => {
            window.removeEventListener('hashchange', scroll, false);
        };
    }, [target]);
};
