/* eslint-disable @next/next/no-img-element */

import styled from 'styled-components';

const HiddenPixelSegment = styled.img`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
`;

export const SegmentPixel = () => {
  return (
    <HiddenPixelSegment
      src="https://segment.prod.bidr.io/associate-segment?buzz_key=metadata&segment_key=metadata-6270&value="
      height="0"
      width="0"
      alt=""
    />
  );
};
