/* eslint-disable @next/next/no-img-element */

import styled from 'styled-components';

const HiddenPixelCapterra = styled.img`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
`;

export const CapterraPixel = () => {
  return (
    <HiddenPixelCapterra
      src="https://ct.capterra.com/capterra_tracker.gif?vid=2342797&vkey=388230c94fd30b51e848f74436260d45"
      height="0"
      width="0"
      alt=""
    />
  );
};
