import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { DefaultSeo } from 'next-seo';

//helpers
import { useScrollToElementOnHashChange } from 'hooks/useScrollToElementOnHashChange';

//defaults
import '@/public/styles/globals.css';

//theme and providers
import { ThemeProvider } from 'styled-components';
import { loadsmart } from 'theme';

//extra scripts
import { GoogleTagManager, GoogleTagManagerNoScript } from 'vendor';

// components
import { FloatingPhone } from '@/components/FloatingPhone';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useScrollToElementOnHashChange('#request-a-demo');

  const isRouteMatching = (values) => {
    if (Array.isArray(values))
      return values.some((path) => router.pathname === path);
    return router.pathname === values;
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <meta name="theme-color" content="#33cc60" />
        <link
          rel="shortcut icon"
          type="image/png"
          href="https://loadsmart.com/images/favicons/favicon-96x96.png"
          sizes="96x96"
        ></link>
        <link
          rel="mask-icon"
          href="https://loadsmart.com/images/favicons/safari-pinned-tab.svg"
          color="#33cc60"
        ></link>
      </Head>
      <DefaultSeo
        title="Move More With Less"
        titleTemplate="Loadsmart | %s "
        description="Loadsmart offers transportation services and software solutions that enables shippers, carriers, and warehouses to move more with less."
        canonical="https://loadsmart.com/"
        openGraph={{
          type: 'website',
          locale: 'en_US',
          site_name: 'Loadsmart',
          url: 'https://loadsmart.com/',
          images: [
            {
              url: `${process.env.SITE_URL}/images/share/loadsmart-card.png`,
              width: 1200,
              height: 630,
              alt: 'Loadsmart',
              type: 'image/png',
            },
          ],
        }}
        twitter={{
          site: '@loadsmartUS',
          cardType: 'summary_large_image',
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: 'https://loadsmart.com/images/favicons/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: 'https://loadsmart.com/apple-touch-icon.png',
            sizes: '76x76',
          },
          {
            rel: 'icon',
            href: 'https://loadsmart.com/images/favicons/favicon-16x16.png',
            sizes: '16x16',
          },
          {
            rel: 'icon',
            href: 'https://loadsmart.com/images/favicons/favicon-32x32.png',
            sizes: '32x32',
          },
          {
            rel: 'icon',
            href: 'https://loadsmart.com/images/favicons/favicon-48x48.png',
            sizes: '48x48',
          },
          {
            rel: 'manifest',
            href: '/manifest.json',
          },
          {
            rel: 'preload',
            href: '/fonts/SharpSans/SharpSans-Medium.woff',
            as: 'font',
            type: 'font/woff',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff2',
            as: 'font',
            type: 'font/woff2',
            crossOrigin: 'anonymous',
          },
        ]}
      />

      {process.env.NOINDEX_FLAG ? '' : <GoogleTagManager />}
      {process.env.NOINDEX_FLAG ? '' : <GoogleTagManagerNoScript />}

      <ThemeProvider theme={loadsmart}>
        <Component {...pageProps} />
      </ThemeProvider>
      <FloatingPhone
        hide={isRouteMatching(['/carrier/tms', '/carrier/tms/features'])}
        position={isRouteMatching(['/shipper/shipperguide']) ? 'right' : 'left'}
      />
    </>
  );
}

export default MyApp;
