export default function GoogleTagManagerNoScript({}) {
  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NH3X9TP"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      }}
    />
  );
}
export { GoogleTagManagerNoScript };
