const COLORS = {
  primary: '#33cc60',
  accent: '#008800',
  greens: {
    light: '#22dd76',
    lightest: '#f3ffed',
    strong: '#0fb941',
    stronger: '#0CA933',
    loadsX400: '#29d454',
    x50: '#e5f9e8',
    x100: '#c2f0c5',
    x200: '#97e6a0',
    x300: '#63dd76',
    x400: '#33CC60',
    x500: '#00cb2c',
    x600: '#00bb21',
    x800: '#009600',
    x900: '#007600',
    x080: '#008800',
  },
  neutral: {
    darkest: '#000000',
    darker: '#33333D',
    dark: '#333B43',
    default: '#8F8F9B',
    light: '#BDC9C6',
    lighter: '#D9E2DF',
    lighter2: '#F6F7F8',
    lighter3: '#EEFAF5',
    lightest: '#FFFFFF',
  },
  greys: {
    x100: '#fafafa',
    x200: '#f5f5f5',
    x300: '#f0f0f0',
    x400: '#dedede',
    x500: '#c2c2c2',
    x600: '#979797',
    x700: '#818181',
    x800: '#606060',
    x900: '#3c3c3c',
  },
  success: {
    dark: '#2A5C85',
    default: '#55B0FB',
    light: '#C0E1F5',
  },
  transparent: 'transparent',
  warning: {
    'warning-dark': '#955800',
    default: '#FFCE4F',
    'warning-light': '#FFF5D6',
    dark: '#D61616',
  },
  danger: {
    dark: '#8F002B',
    default: '#E80045',
    light: '#FFD6E2',
  },
};

const FONT_FAMILIES = {
  title: "'Sharp Sans', Manrope, sans-serif",
  default: "'Mont', Manrope, Arial, sans-serif",
  monospace: 'Roboto, monospace',
};

const FONT_SIZES = {
  5: '1.2rem',
  4: '1.4rem',
  3: '1.6rem',
  2: '2.0rem',
  1: '2.6rem',
};

const FONT_WEIGHTS = {
  light: 200,
  book: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const FONT_HEIGHTS = {
  1: '100%',
  2: '120%',
  3: '150%',
};

const BREAKPOINTS = {
  small: '375px',
  medium: '768px',
  big: '1024px',
  bigger: '1220px',
  biggest: '1920px',
};

const loadsmart = {
  breakpoint: BREAKPOINTS,
  color: COLORS,
  font: {
    family: FONT_FAMILIES,
    size: FONT_SIZES,
    weight: FONT_WEIGHTS,
    height: FONT_HEIGHTS,
  },
};

export default loadsmart;
export { loadsmart };
