import { css } from 'styled-components';

export const intSizes = {
  sm: 375,
  md: 768,
  lg: 1024,
  xlg: 1220,
  xxlg: 1920,
};

export const sizes = Object.keys(intSizes).reduce(
  (result, sizeName) => ({
    ...result,
    [sizeName]: `${intSizes[sizeName]}px`,
  }),
  {}
);

export const screen = Object.keys(intSizes).reduce(
  (result, sizeName) => ({
    ...result,
    [sizeName]: `@media (min-width: ${sizes[sizeName]})`,
  }),
  {}
);

const hide = css`
  display: none;
`;

export const hideOnMedium = css`
  ${screen.md} {
    ${hide};
  }
`;

export const hideOnMobile = css`
  ${hide}
  ${screen.md} {
    display: inline-flex;
  }
`;

export const hideOnLarge = css`
  ${screen.lg} {
    ${hide};
  }
`;

export const showOnLarge = css`
  ${hide} ${screen.lg} {
    display: inline-flex;
  }
`;
