import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

// assets
import { IconPhone } from '@loadsmart/icons';

export const FloatingPhoneWrapper = styled.div`
  position: fixed;
  bottom: 8px;
  z-index: 999;

  right: ${conditional({
    '8px': (props) => props.position !== 'right',
    unset: (props) => props.position !== 'left',
  })};

  left: ${conditional({
    '8px': (props) => props.position !== 'left',
    unset: (props) => props.position !== 'right',
  })};

  display: ${conditional({
    flex: (props) => props.hide !== true,
    none: (props) => props.hide !== false,
  })};

  justify-content: space-between;
  align-items: center;
  border-radius: 28px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 50%);
  background: rgb(${getToken('color-success-60')});
  color: #ffffff;

  width: 136px;
  height: 40px;
  padding: 12px 14px;
  font-size: 11px;

  a {
    text-decoration: none;
    color: inherit;
  }

  ${screen.md} {
    border-radius: 8px 8px 0 0;
    bottom: 0;
    font-size: 13px;
    padding: 14px 18px;
    width: 160px;
    height: 50px;

    right: ${conditional({
      '16px': (props) => props.position !== 'right',
      unset: (props) => props.position !== 'left',
    })};

    left: ${conditional({
      '16px': (props) => props.position !== 'left',
      unset: (props) => props.position !== 'right',
    })};

    display: ${conditional({
      flex: (props) => props.hide !== true,
      none: (props) => props.hide !== false,
    })};
  }
`;

export const IconWrapper = styled.div`
  margin: 4px 4px 0 0;

  svg {
    max-width: 14px;
    height: auto;
  }

  ${screen.md} {
    margin: 8px 8px 0 0;

    svg {
      max-width: unset;
    }
  }
`;

export default function FloatingPhone({ hide, position }) {
  return (
    <FloatingPhoneWrapper hide={hide} position={position}>
      <IconWrapper>
        <IconPhone width="18" height="18" />
      </IconWrapper>
      <a href="tel:+1 646-887-6278">(646) 887 6278</a>
    </FloatingPhoneWrapper>
  );
}

export { FloatingPhone };
